<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-form
          ref="form"
          lazy-validation
          class="app-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <v-card class="app-card">
            <v-card-title class="app-card-title">
              <h4 class="text">
                <span>{{ currentRoute === "dashboard-usuarios-novo" ? 'Novo' : 'Editar' }} Usuário</span>
              </h4>
            </v-card-title>
            <v-card-text class="app-card-content">
              <v-row>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.name"
                    outlined
                    dense
                    label="Nome *"
                    placeholder="Digite o nome"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.email"
                    outlined
                    dense
                    label="Email *"
                    placeholder="Digite o email"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  v-if="currentRoute === 'dashboard-usuarios-novo'"
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.password"
                    outlined
                    dense
                    label="Senha *"
                    placeholder="Digite a senha"
                    type="password"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="form.profile"
                    outlined
                    dense
                    chips
                    small-chips
                    label="Perfil *"
                    item-text="name"
                    return-object
                    :items="profiles"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.crea"
                    outlined
                    dense
                    label="Crea"
                    placeholder="Digite o crea se engenheiro"
                    type="text"
                  />
                </v-col>
                <v-col
                  v-if="currentRoute === 'dashboard-usuarios-editar'"
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-switch
                    v-model="form.active"
                    :label="`Ativo`"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="app-card-actions">
              <span class="app-form__warning">
                Atenção: Campos que possuem o
                <em>*</em> são obrigatórios
              </span>
              <v-spacer />
              <v-btn
                depressed
                outlined
                color="error"
                @click="$router.back()"
              >
                Voltar
              </v-btn>
              <v-btn
                depressed
                color="success"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
            <section
              v-if="detail.isLoading"
              class="loading"
            >
              <v-progress-circular
                indeterminate
                color="success"
              />
              <span>Enviando informações ...</span>
            </section>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  export default {
    data: () => ({
      currentRoute: null,
      form: {
        name: null,
        email: null,
        password: null,
        active: null,
        profile: null,
        crea: null
      },
      profiles: [],
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido',
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(value) || 'E-mail fora do padrão';
        }
      }
    }),
    computed: {
      ...mapState('usuario', ['detail'])
    },
    mounted() {
      this.loadProfiles();
      this.iniData();
    },
    destroyed() {
      this.clearResource();
    },
    methods: {
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          if (this.currentRoute === 'dashboard-usuarios-novo') {
            const { name, email, password, profile, crea } = this.form;
            const body = { name, email, password, profile, crea: crea };
            this.create(body);
          } else {
            const { crea } = this.form;
            const form = {...this.form, crea: crea }
            this.update(form);
          }
        }
      },
      async loadProfiles() {
        this.profiles = await this.listProfiles();
      },
      async iniData() {
        const {
          name,
          params: { id }
        } = this.$route;

        this.currentRoute = name;

        if (name === 'dashboard-usuarios-editar') {
          await this.getUsuario(id);
          this.form = { ...this.detail.data };
        }
      },
      ...mapActions('usuario', { create: 'CREATE' }),
      ...mapActions('usuario', { update: 'UPDATE' }),
      ...mapActions('usuario', { getUsuario: 'SHOW' }),
      ...mapActions('usuario', { clearResource: 'CLEAR_DETAIL' }),
      ...mapActions('perfil', { listProfiles: 'LIST' })
    },
  };
</script>
